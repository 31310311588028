import React from 'react';

import { TermsAndConditionsContentContainer, Title, Sub } from './TermsAndConditionsStyles';

export const TermsAndConditionsContainer = () => {
  return (
    <TermsAndConditionsContentContainer>
      <Title>Terms and Conditions</Title>
      <Sub>Last Updated May 1, 2024</Sub>
      <h3>Scope of application</h3>
      <ul>
        <li>
          1.1 The present Terms and Conditions, together with its Schedules, are applicable to each Order Form executed
          between Oqcam and Customer regarding the Software and to related services offered by Oqcam to Customer.
        </li>
        <li>
          1.2 Customer agrees to waive its own general and special terms and conditions, even where it is stated therein
          that only those conditions apply and even if such terms and conditions were not protested by Oqcam. This
          Agreement shall prevail over any differing or additional terms and conditions proposed by Customer, including,
          without limitation, those contained in any purchase order issued by Customer.
        </li>
      </ul>
      <h3> Definitions </h3>
      <ul>
        <li>
          Capitalised notions used throughout the Agreement shall have the meaning given to them below, unless stated
          otherwise:
        </li>
        <li>"Account" means an account enabling a User to access and/or use (as applicable) the Software.</li>
        <li>
          "Affiliate" means, with respect to either Party, any individual, company, corporation, trust, partnership or
          other entity, directly or indirectly, controlling, controlled by or under common control with such Party.
        </li>
        <li>
          "Agreement" means the entire contractual relation between Oqcam and Customer, consisting of (i) these Terms
          and Conditions, (ii) the relevant Order Form(s) and (iii) the Schedules.
        </li>
        <li>
          "Business Day" means a normal working day of Oqcam, starting from 8:30 am to 5:30 pm CET from Monday to
          Friday, excluding legal holidays in Belgium.
        </li>
        <li>
          "Confidential Information" of a Party means the information of such Party, whether in written, oral,
          electronic or other form, and which (i) is designated as confidential or proprietary, or (ii) should
          reasonably be considered confidential given its nature or the circumstances surrounding its disclosure,
          regardless of whether or not it is expressly designated as confidential, including information and facts
          concerning business plans, customers, prospects, personnel, suppliers, partners, investors, affiliates or
          others, training methods and materials, financial information, marketing plans, sales prospects, customer
          lists, inventions, program devices, discoveries, ideas, concepts, know-how, techniques, formulas, blueprints,
          software, documentation, designs, prototypes, methods, processes, procedures, codes, and any technical or
          trade secrets.
        </li>
        <li>
          "Customer", "you" or "your" means the legal entity entering into contractual relations with Oqcam, as
          identified in the Order Form.
        </li>
        <li>
          "Customer Data" means any information, data and files made available, transmitted or uploaded to the Software
          by Customer or Users, or by Oqcam on Customer's behalf for the purpose of facilitating Customer’s use of the
          Software.
        </li>
        <li>"Customer Personal Data" means all Personal Data within Customer Data.</li>
        <li>
          “Data Processing Agreement” means the data processing agreement attached hereto as Annex 1 (Data Processing
          Agreement).
        </li>
        <li>
          "Data Protection Laws" means all relevant regulation, national or international, concerning privacy and data
          protection, including but not limited to the General Data Protection Regulation ("GDPR").
        </li>
        <li>
          “Defect" means a defect, error or bug in the Software having a material adverse effect on operation,
          functionality or performance of the Software but excluding any defect, error or bug caused by or arising as a
          result of (i) any act or omission of Customer and/or the Users; (ii) any use of the Software contrary to the
          documentation or the Agreement; (iii) a failure of Customer and/or the Users to perform or observe any of its
          obligations under the Agreement and/or the EULA; and/or (iv) an incompatibility between the Software and any
          other system, network, application, program, hardware or software.
        </li>
        <li>"Deliverable" means any concrete output provided by Oqcam as a result of the Professional Services.</li>
        <li>
          "Delivery Date" means the date on which the Software is delivered and/or made available to Customer, as set
          out in the Order Form.
        </li>
        <li>"Effective Date" means the effective date as set out in the Order Form.</li>
        <li>
          "End User License Agreement" or “EULA” means the terms of use governing the use of the Software by Customer
          and/or the Users, including at least the provisions explicitly requested in this Agreement and as may be
          modified from time to time (as requested) by Oqcam.
        </li>
        <li>"Fees" means any and all amounts payable by Customer to Oqcam under the Agreement.</li>
        <li>
          "Hardware" means any factory, workbench or machine-level industrial computer leased by Oqcam to Customer as
          set out in the Order Form. “Hosting Partner” means the hosting partner as described in clause 16 of these
          Terms and Conditions (or such other provider of hosting services Oqcam might contract with in the future, as
          will be notified to Customer from time to time);
        </li>
        <li>"Initial Term" shall have the meaning given to it in clause 13 of these Terms and Conditions.</li>
        <li>
          "Intellectual Property Rights" means all intellectual or industrial property right or equivalent, whether
          registered or unregistered, including but not limited to: (i) copyright (including moral rights), patents,
          database rights and rights in trademarks, logos, designs, other artwork, know-how and trade secrets and other
          protected undisclosed information; and (ii) applications for registration, and the right to apply for
          registration, renewals, extensions, continuations, divisions, reissues, or improvements for or relating to any
          of these rights.
        </li>
        <li>
          "Order Form" means a written document entered into between the Parties, regardless of its name (e.g.
          "quotation" or "product order"), indicating the pricing and other specifics of the Software and (if
          applicable) a description of the Professional Services, and which forms an integral part of the Agreement.
        </li>
        <li>
          "Oqcam", "our", "we" or "us" means Oqcam, BV., a Belgian corporation with an address located at Bomastraat 10, 
          9000 Gent, Belgium, and its affiliates.
        </li>
        <li>"Party" means Customer and/or Oqcam.</li>
        <li>
          "Personal Data" shall have the meaning given to it in article 4 (1) of the General Data Protection Regulation.
        </li>
        <li>
          "Professional Services" means any professional services, including customizations, as indicated in the Order
          Form or as may be agreed in writing between the Parties from time to time.
        </li>
        <li>"Renewal Term" shall have the meaning given to it in clause 13 of these Terms and Conditions.</li>
        <li>
          "Schedule" means any annex, schedule, appendix or other complementary document which forms a part of the
          Agreement.
        </li>
        <li>"Software" means Oqcam software, an end-to-end manufacturing platform, developed and managed by Oqcam.</li>
        <li>
          "Support Services" means the maintenance and support services related to the Software provided by Oqcam to
          Customer.
        </li>
        <li>"Term" means the Initial Term and any Renewal Term(s).</li>
        <li>"Terms and Conditions" means the present terms and conditions, including its Schedules.</li>
        <li>
          "Users" means Customer employees or consultants, who are authorized by Customer to access and/or use (as
          applicable) the Software through an Account.
        </li>
      </ul>
      <h3>License</h3>
      <ul>
        <li>
          3.1 In consideration of Customer’s compliance with the provisions as set out in this Agreement (including
          timely payment of all Fees), Oqcam grants Customer, during the Term, a personal, restricted, non-exclusive,
          non- transferrable, non-sublicensable, non-assignable, revocable, worldwide license to access and use the
          Software (including any customizations) for Customer's internal business purposes.
        </li>
        <li>
          3.2 Customer shall at all times comply with (i) the applicable documentation; (ii) the provisions of the
          Agreement; and (iii) any reasonable instruction of Oqcam.
        </li>
        <li>3.3 Customer explicitly agrees (and shall procure that its Users agree) not to, directly or indirectly:</li>
        <ul>
          <li>(i) use the Software other than in accordance with the Software’s intended purpose and the Agreement;</li>
          <li>
            (ii) use the Software in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any
            unlawful, illegal, fraudulent or harmful purpose or activity;
          </li>
          <li>
            (iii) sell, lease, commercialize, rent, display, license, sublicense, transfer, provide, disclose, or
            otherwise make available to, or permit access to (or use of), the Software or the documentation, in whole or
            in part, to (or by) any third party (other than Users), whether or not related to Customer, except as
            expressly permitted in this Agreement;
          </li>
          <li>
            (iv) modify the Software or develop any derivative works based on the Software or any Confidential
            Information of Oqcam;
          </li>
          <li>
            (v) decompile, disassemble, translate, reverse engineer or attempt to reconstruct, identify or discover,
            copy, create derivative works based upon the source code of the Software (unless permitted by law),
            underlying ideas, underlying user interface techniques or algorithms of the Software by any means whatsoever
            (except to the extent such restriction is prohibited under applicable law), or disclose any of the
            foregoing;
          </li>
          <li>(vi) encumber or suffer to exist any lien or security interest on the Software;</li>
          <li>
            (vii) take any action that would cause the Software or the source code to be placed in the public domain;
            and
          </li>
          <li>(viii) work around any technical limitation in the Software.</li>
        </ul>
        <li>
          3.4 Customer shall comply with and shall procure that its Users shall comply with (i) all applicable laws
          relating to the use of the Software and (ii) the EULA.
        </li>
        <li>
          3.5 Customer shall have the right to make the Software available to the Users subject to the following
          conditions:
        </li>
        <li>
          (i) each User shall only be allowed to install, access and/or use (as applicable) the Software after having
          explicitly accepted the latest version of the EULA;
        </li>
        <li>(ii) the EULA shall be at least as protective for Oqcam as the provisions included in this Agreement.</li>
        <li>
          3.6 The license to the Software, is expressly restricted to the rights, limitations and other terms set forth
          in the Agreement and Customer shall not be able to invoke any alleged implied rights which are not expressly
          set out under the Agreement. For the avoidance of doubt, the Software is only licensed, not sold to Customer.
        </li>
        <li>
          3.7 Customer acknowledges and agrees that any use of the Software outside the scope of and/or in breach of the
          terms as set forth in this Agreement, shall entitle Oqcam to immediately terminate (or alternatively, at
          Oqcam’s option, suspend) this Agreement for material breach by Customer, without any formalities being
          required and without prejudice to any other right or remedy available to Oqcam pursuant to this Agreement or
          under applicable law.
        </li>
        <li>
          3.8 Customer hereby acknowledges and agrees that any modification or attempted modification of the Software by
          any party other than Oqcam shall void Oqcam’s warranties and shall be deemed to represent a material breach of
          this Agreement by Customer.
        </li>
      </ul>
      <h3> Professional Services</h3>
      <ul>
        <li>
          4.1 At the request of Customer, Oqcam may accept to provide Professional Services to Customer. Oqcam shall
          provide the Professional Services in complete independence and shall plan its activities as it sees fit.
        </li>
        <li>
          4.2 Unless otherwise stated in the Order Form, all Professional Services are charged on a 'time and material'
          basis.
        </li>
        <li>
          4.3 Customer acknowledges and agrees that in order for Oqcam to effectively perform the Professional Services
          in a proper, timely and efficient manner, Customer must cooperate with Oqcam by (i) making available on a
          timely basis management decisions, information, and approvals; and (ii) at no cost to Oqcam, providing timely
          and appropriate access to Customer facilities, personnel, equipment, resources and systems, and any relevant
          Customer Data, information and documentation (to be accurate and complete) as necessary to facilitate
          performance of the Professional Services. Customer guarantees that all requested customizations shall (i)
          comply with applicable laws; and (ii) shall not infringe upon the rights of any third parties.
        </li>
        <li>4.4 The Deliverables shall be deemed accepted by Customer upon delivery.</li>
      </ul>
      <h3>Hardware</h3>
      <ul>
        <li>
          5.1 Oqcam shall retain ownership of and title to the Hardware at all times. Customer shall have a duty of care
          in respect of the Hardware and must maintain it in perfect condition in accordance with the highest standards
          and safety requirements prevailing in the industry. Customer shall insure the Hardware against all normal
          risks (such as, but not limited to, fire, water damage, theft) and provide the relevant insurance policy to
          Oqcam upon first request.
        </li>
        <li>
          5.2 If the Hardware is placed in a location or place that does not belong to Customer, Customer undertakes to
          take all measures and make all contractual agreements with the owner of such location or site so that Oqcam’s
          rights as stipulated in this Agreement are safeguarded. Under no circumstances shall Customer move or store
          the Hardware without the express prior written consent of Oqcam.
        </li>
        <li>
          5.3 The Hardware must be returned to Oqcam (i) in the event of a replacement of the Hardware by a newer model,
          and (ii) upon termination of the Agreement for any reason or expiry of its Term.
        </li>
      </ul>
      <h3> Delivery of the Software</h3>{' '}
      <ul>
        <li>
          6.1 A Customer specific Software environment shall be set up by Oqcam, Oqcam shall create an administrator
          Account for Customer and shall provide Customer with access credentials on the delivery date indicated in the
          Order Form.
        </li>
        <li>
          6.2 Customer expressly acknowledges that the operation of the Software is subject to the technical
          requirements communicated by Oqcam and may require that Customer and/or the Users have or obtain, at their
          sole cost and expense, high-speed internet connection, hardware, networks, operating systems and/or other
          third-party software.
        </li>
      </ul>
      <h3> Maintenance and changes</h3>
      <ul>
        <li>
          7.1 Oqcam reserves the right to make operational or technical changes to the Software, and to modify, add or
          remove certain functionalities.
        </li>
        <li>
          7.2 Customer acknowledges that in order to ensure a correct functioning of the Software, maintenance services
          are needed from time to time. Oqcam shall carry out such maintenance services at its sole discretion and shall
          use all reasonable endeavours to minimise the impact on Customer’s day to day business. Where practicable,
          Oqcam shall give to Customer prior written notice of scheduled maintenance that is likely to affect the
          availability of the Software or is likely to have a material negative impact upon the Software. Oqcam shall
          not be liable for costs incurred by Customer as a result of any non-availability of the Software due to
          maintenance services for which prior written notice has been given.
        </li>
      </ul>
      <h3>Support</h3>
      <ul>
        <li>
          If no specific service levels have been agreed in writing between the Parties, Oqcam shall use commercially
          reasonable efforts to correct (or the provide a workaround for) any reproducible Defects in the Software as
          soon as reasonably possible after Customer has logged the Defect by using the ticketing system chosen by
          Oqcam.
        </li>
      </ul>
      <h3>Intellectual Property Rights</h3>
      <ul>
        <li>
          9.1 Oqcam is and remains the sole and exclusive proprietary owner of all Intellectual Property Rights related
          to the Software, Hardware and the documentation (including any new versions, updates, customizations,
          enhancements, modifications or improvements made to the Software or documentation).
        </li>
        <li>
          9.2 Nothing in the Agreement shall convey any title or proprietary right or Intellectual Property Rights in or
          over the Software and the documentation to Customer or any third party. Customer shall not in any way acquire
          any title, rights of ownership, copyright, intellectual property rights or other proprietary rights of
          whatever nature in the Software or documentation. Customer agrees not to remove or modify in any way any
          proprietary marking, including any trademark or copyright notice, on or in the Software, or visible during its
          operation, or on media or on any documentation. Customer shall incorporate or reproduce such proprietary
          markings in any permitted back-up or other copies.
        </li>
      </ul>
      <h3>Customer Data</h3>
      <ul>
        <li>10.1 All Customer Data shall remain property of Customer.</li>
        <li>
          10.2 Customer hereby grants to Oqcam a non-exclusive licence to copy, reproduce, store, distribute, publish,
          export, adapt, edit and translate Customer Data to the extent reasonably required for the performance of
          Oqcam’s obligations and the exercise of Oqcam’s rights under this Agreement.
        </li>
        <li>
          10.3 Customer warrants to Oqcam that Customer’s Data when used by Oqcam in accordance with this Agreement will
          not infringe the Intellectual Property Rights or other legal rights of any third party, and will not breach
          the provisions of any law, statute or regulation, in any jurisdiction and under any applicable law.
        </li>
        <li>
          10.4 Customer Data and use data generated from your use of the Software can be used by Oqcam to provide you
          and other customers with improved Software. By using the Software, you agree to such use of your Data for such
          purposes.
        </li>
      </ul>
      <h3>Usage Control</h3>
      <ul>
        <li>
          11.1 Oqcam has the right to (have a third party) monitor and inspect the usage of the Software by Customer in
          order to improve the Software and to verify the calculation of the Fees.
        </li>
        <li>
          11.2 If such inspection shows that Customer has underpaid the amount of Fees due to Oqcam, without prejudice
          to any other rights and remedies available to Oqcam, Customer shall promptly pay the amount of such
          underpayment to Oqcam together with any applicable late payment interest.
        </li>
      </ul>
      <h3>Fees and Payment Terms</h3>
      <ul>
        <li>12.1 Customer shall pay Oqcam the Fees set forth in the Order Form, in accordance with this Agreement.</li>
        <li>
          12.2 In addition to the Fees, Oqcam reserves the right to request the payment of a guarantee for the use of
          the Hardware by Customer. Such guarantee will be used by Oqcam to offset (part of) any damages caused by
          Customer to the Hardware. After the Term, the remaining sum of the guarantee shall be offset to any
          outstanding invoices for Customer.
        </li>
        <li>
          12.3 If not otherwise specified in the Order Form, the Fees shall be invoiced by Oqcam on a yearly basis as
          from the Effective Date or each successive anniversary thereof.
        </li>
        <li>
          12.4 All invoices are payable within thirty (30) calendar days after the invoice date. If a Customer disputes
          an invoice (or any portion thereof), Customer must notify Oqcam in writing of the nature of such dispute
          within ten (10) Business Days after the invoice date, failing which the invoice shall be deemed accepted by
          Customer. The undisputed portion of the invoice shall be paid as set forth in the Agreement. If Customer fails
          to pay any outstanding amounts, Oqcam shall be entitled to suspend its obligations and Customer’s rights
          hereunder until receipt of payment of such outstanding amounts.
        </li>
        <li>12.5 Payments made by Customer to Oqcam under the Agreement shall be final and non-refundable.</li>
        <li>
          12.6 All Fees payable to Oqcam under the Agreement shall be paid without the right to set off or counterclaim
          and free and clear of all deductions or withholdings whatsoever unless the same are required by law, in which
          case Customer undertakes to pay Oqcam such additional amounts as are necessary in order that the net amounts
          received by Oqcam after all deductions and withholdings shall not be less than such payments would have been
          in the absence of such deductions or withholding.
        </li>
        <li>
          12.7 Unless specified otherwise in the Order Form, invoices shall be sent electronically to Customer’s e-mail
          address, set out the Order Form.
        </li>

        <li>
          12.8 Any amounts of undisputed invoices that have not been paid within thirty (30) days after the invoice date
          shall automatically and without notice be subject to a late payment interest equal to 1,5% per month or the
          maximum permitted by applicable law, which interest shall be compounded daily as of the due date until receipt
          of full payment by Oqcam. In addition, Customer shall pay all costs incurred by Oqcam as a result of the
          (extra)judicial enforcement of Customer’s payment obligation under this clause.
        </li>
      </ul>
      <h3>Term of the Agreement</h3>
      <ul>
        <li>
          13.1 The Agreement is entered into for (i) the fixed term set out in the Order Form or (ii) if no term is set
          out in the Order Form, for one (1) year (the "Initial Term").
        </li>
        <li>
          13.2 After the Initial Term, the Agreement shall be automatically and tacitly renewed for successive one-year
          periods (each a "Renewal Term"), unless a Party provides a written notice to terminate at least three (3)
          months before the end of the Initial Term or the then-running Renewal Term.
        </li>
      </ul>
      <h3>Termination</h3>
      <ul>
        <li>
          14.1 Oqcam may, at its sole discretion, suspend or terminate the Agreement, partially or wholly, by written
          notice to Customer, if Customer fails to pay to Oqcam any amount due under the Agreement and Customer fails to
          cure such failure to pay within thirty (30) days from the date of a written notice of default from Oqcam to
          Customer.
        </li>
        <li>
          14.2 Either Party may immediately terminate the whole or any portion of the Agreement without any judicial
          intervention, without being liable for compensation and without prejudice to its rights to damages and any
          other rights, remedies and/or claim to which it may be entitled by law, upon providing the other Party with
          written notice of termination if:
        </li>
        <ul>
          <li>
            (i) the other Party performs a material breach to any provision of the Agreement and fails to cure such
            material breach within thirty (30) calendar days after receipt of written notice of the material breach;
          </li>
          <li>
            (ii) the other Party becomes insolvent, is subject to voluntary or involuntary bankruptcy, insolvency or
            similar proceeding or otherwise liquidates or ceases to do business; or
          </li>
          <li>
            (iii) the other Party breaches clause 9 (Intellectual Property Rights) or clause 19 (Confidential
            Information).
          </li>
        </ul>
        <li>14.3 Upon termination of the Agreement:</li>
        <ul>
          <li>
            (i) Customer’s and the Users’ right to use the Software will automatically cease and all licenses granted to
            Customer pursuant to the Agreement shall automatically terminate;
          </li>
          <li>
            (ii) each Party shall delete or return, within reasonable time of such termination or expiration, all
            Confidential Information from the other Party, except as required to comply with any applicable legal or
            accounting record keeping requirement; and
          </li>

          <li>
            (iii) Customer shall promptly pay Oqcam all Fees due to Oqcam up to and including the date of termination.
          </li>
        </ul>
      </ul>
      <h3>Warranties</h3>
      <ul>
        <li>
          15.1 Customer acknowledges and agrees that the Software is provided “as is”. Except for the foregoing warranty
          and to the maximum extent permitted by applicable law, Oqcam does not make any representations or warranties,
          express or implied, concerning any matter under this Agreement and Oqcam disclaims any representations or
          warranties, express or implied, including (without limitation) any implied warranties of accuracy or
          completeness of data, fitness for a particular purpose, merchantability, or non-infringement.
        </li>
        <li>
          15.2 Customer recognizes that the Software cannot in any way be considered as a replacement of the services
          offered by (safety) engineers and/or other experts involved in designing and creating the end-product.
        </li>
      </ul>
      <h3>Hosting</h3>
      <ul>
        <li>
          16.1 The Software is hosted by the Hosting Partner and Oqcam is entitled to provide Hosting Partner’s services
          (“Hosting Services”) to Customer. Customer represents and warrants that it accepts the terms and conditions of
          the last version of the Hosting Partner’s terms of use as available on https://policies.google.com/terms?hl=en
          (“Google Terms”) and acknowledges and agrees that the Hosting Partner shall retain the right to unilaterally
          change these terms at any time.
        </li>
        <li>
          16.2 Customer acknowledges that the Hosting Services will be performed in dedicated Google data centres. The
          warranties set out in the Google Terms are not applicable in case the non-conformity with the service level
          terms and conditions is due to accident, misuse or use in any other way which is not compatible with the
          Google Terms or the present Agreement. Oqcam does not give any other warranties and rejects all other
          explicit, inexplicit or legal warranties, including warranties in respect of merchantability, fitness for a
          particular purpose, adequate quality, title or non-infringement. Oqcam shall in no event provide any warranty
          in respect of the Hosting Services.
        </li>
        <li>
          16.3 Customer on behalf of its Users gives the Hosting Partner the permission to process all Personal Data as
          contemplated by this Agreement.
        </li>
      </ul>
      <h3>Privacy and Data Protection</h3>
      <ul>
        <li>
          17.1 Each Party shall comply with the Data Protection Laws with respect to the processing of Customer Personal
          Data.
        </li>
        <li>
          17.2 Customer represents and warrants to Oqcam that it has the legal right to disclose any Personal Data that
          is available to Oqcam under or in connection with this Agreement and that Customer has a valid legal ground to
          process such personal data and to disclose such Personal Data to Oqcam in accordance with applicable law.
          Customer undertakes to sufficiently inform all data subjects about such processing activities by Customer
          and/or Oqcam (as applicable) in accordance with applicable law.
        </li>
        <li>
          17.3 Oqcam shall process Customer Personal Data in accordance with the Data Processing Agreement as concluded
          between the Parties and as attached hereto in Annex 1 (Data Processing Agreement).
        </li>
        <li>
          17.4 If any changes or prospective changes to the Data Protection Laws result or will result in one or both
          Parties not complying with the Data Protection Laws in relation to processing of Personal Data carried out
          under this Agreement, then the Parties shall use their best endeavours promptly to agree such variations to
          this Agreement as may be necessary to remedy such non-compliance.
        </li>
      </ul>
      <h3>Infringement Claims by third parties</h3>
      <ul>
        <li>
          18.1 If any third party claims the Software infringes their patent, copyright, trade secret or other
          proprietary right, Oqcam will indemnify and hold Customer harmless from any damages, judgments or settlements
          (including costs and reasonable attorney’s fees) resulting from the claim if Customer promptly notifies Oqcam
          in writing of the claim and permits Oqcam to elect to take over the defense of the action. If Oqcam takes over
          the defense, it may select the counsel and have the sole right to defend or settle the matter..
        </li>
        <li>
          18.2 In the event that the Software threatens to become the subject or is the subject of an infringement claim
          as set out above, Oqcam will be entitled at its own option and expense (i) to modify or replace the allegedly
          infringing material so that it is no longer infringing material, while maintaining substantially similar
          functionality; or (ii) to obtain the right for Customer to continue to use, market and distribute the relevant
          material in accordance with the Terms and Conditions.
        </li>
        <li>
          18.3 Oqcam is not liable for any claim that is based on (i) unauthorized use of the Software by Customer, (ii)
          a modification by Customer or a third party of the Software, (iii) the failure of the integration or
          installing improvements by Customer in respect of the Software issued by Oqcam, if Oqcam has indicated that
          such improvements or adjustments are necessary to prevent a potential breach; or (iv) the use of the Software
          by Customer in an incompatible or unauthorized combination with non-Oqcam products or services.
        </li>
      </ul>
      <h3>Confidential Information</h3>
      <ul>
        <li>
          19.1 Parties shall treat all Confidential Information received from the other Party as confidential, keep it
          secret and shall not disclose it to any third party, other than its agents, employees, advisors or consultants
          where such disclosure is necessary for the performance of the Agreement and only in case such agents,
          employees, advisors or consultants are bound by a confidentiality obligation at least as strict as included in
          the Agreement.
        </li>
        <li>
          19.2 Confidential information disclosed in the execution of this Agreement shall not be used for any purpose
          other than as required for the performance of either Parties’ obligations under the Agreement.
        </li>
        <li>
          19.3 Both Parties shall take sufficient measures to maintain the confidentiality of all Confidential
          Information. Parties in particular agree that they:
        </li>
        <ul>
          <li>
            (i) shall not copy or otherwise exploit any component of the Confidential Information other than as herein
            provided, nor make any disclosures with reference thereto to any third party; and
          </li>
          <li>
            (ii) shall promptly notify the other Party if it becomes aware of any breach of confidentiality and give the
            other Party all reasonable assistance in connection with the same.
          </li>
        </ul>
        <li>19.4 Shall not be considered Confidential Information, information that:</li>
        <ul>
          <li>(i) is published or comes into the public domain other than by a breach of the Agreement;</li>
          <li>
            (ii) can be proven to have been known by the receiving Party before disclosure by the disclosing Party;
          </li>
          <li>
            (iii) is lawfully obtained from a third party other than by a confidentiality breach of such third party; or
          </li>
          <li>(iv) can be shown to have been created by the receiving Party independently of the disclosure.</li>
        </ul>
        <li>
          19.5 If and to the extent required in accordance with a judicial or other governmental order, the receiving
          Party may disclose Confidential Information, provided that the receiving Party:
        </li>
        <ul>
          <li>
            (i) gives the disclosing Party reasonable prior notice to seek a protective order or equivalent, unless the
            receiving Party is legally prohibited from doing so;
          </li>
          <li>
            (ii) reasonably cooperates with the disclosing Party in its reasonable efforts to obtain a protective order
            or other appropriate remedy;
          </li>
          <li>
            (iii) discloses only that portion of the Confidential Information that is legally required to disclose; and
          </li>
          <li>
            (iv) uses reasonable efforts to obtain reliable written assurances from the applicable judicial or
            governmental entity that it will afford the Confidential Information the highest level of protection
            available under applicable law or regulation.
          </li>
        </ul>
        <li>
          19.6 The obligations set out in this clause 19 shall enter into force as from the start of negotiations
          between the Parties and shall survive during five
        </li>
        <li>
          (5) years after the termination or expiration of the Agreement. The confidentiality obligations in the
          Agreement replace any prior non-disclosure agreement signed between the Parties.
        </li>
      </ul>
      <h3>Limitation of Liability</h3>
      <ul>
        <li>
          20.1 Subject to the maximum extent permitted under applicable law, Oqcam’s liability under the Agreement shall
          per event (or series of connected events) and in the aggregate not exceed the Fees paid by Customer to Oqcam
          under the Agreement for a period of twelve (12) months prior to the date of the event (or last of the series
          of connected events) giving rise to the claim. Notwithstanding the foregoing, the limitation of liability
          within this subsection shall not apply to claims for indemnification pursuant to Section 18 herein.
        </li>
        <li>
          20.2 Oqcam shall under no circumstances be liable to Customer for any indirect, punitive, special
          consequential or similar damages (including damages for loss of profit, lost revenue, loss of business, loss
          or corruption of data, loss of customers and contracts, loss of goodwill, the cost of procuring replacement
          goods or services, and reputational damage) whether arising from negligence, breach of contract or of
          statutory duty or otherwise howsoever, and third parties’ claims. Each Party shall have the duty to mitigate
          damages. The exclusions and limitations of liability under this article shall operate to the benefit of
          Oqcam’s affiliates and subcontractors to the same extent such provisions operate to the benefit of Oqcam.
        </li>
        <li>
          20.3 In case of defective Hardware, Customer may only claim a repair or replacement of the Hardware, without
          Customer being able to claim any additional form of compensation.
        </li>
      </ul>
      <h3>Miscellaneous</h3>
      <ul>
        <li>
          21.1 Entire agreement. The Agreement constitutes the entire agreement and understanding between the Parties
          with respect to the subject matter hereof and supersedes all prior oral or written agreements, representations
          or understandings between the Parties relating to the subject matter hereof. No statement, representation,
          warranty, covenant or agreement of any kind not expressly set forth in the Agreement shall affect, or be used
          to interpret, change or restrict, the express clauses of the Agreement.
        </li>
        <li>
          21.2 Electronic signatures. The words "execution," "signed," "signature," and similar words shall be deemed to
          include electronic signatures or the keeping of records in electronic form, each of which shall be of the same
          legal effect, validity or enforceability as a manually executed signature or the use of a paper-based record
          keeping system, as the case may be, to the extent and as provided for in any applicable law.
        </li>
        <li>
          21.3 Amendments. The terms of the Agreement may be modified or amended only by written agreement executed by a
          duly authorized representative of both Parties hereto.
        </li>
        <li>
          21.4 Waiver. The terms of the Agreement may be waived only by a written document signed by both Parties. Each
          such waiver or consent shall be effective only in the specific instance and for the purpose for which it was
          given, and shall not constitute a continuing waiver or consent.
        </li>
        <li>
          21.5 Severability. If any provision of the Agreement is determined to be illegal, void, invalid or
          unenforceable, in whole or in part, the remaining provisions shall nevertheless continue in full force and
          effect. The provisions found to be illegal, invalid or unenforceable shall be enforceable to the full extent
          permitted by applicable law. Each Party shall use its best efforts to immediately negotiate in good faith a
          valid replacement provision with an equal or similar economic effect.
        </li>
        <li>
          21.6 Survival. Expiration, termination or cancellation of the Agreement shall be without prejudice to the
          rights and liabilities of each Party which have accrued prior to the date of termination, and shall not affect
          the continuance in force of the provisions of the Agreement which are expressly or by implication intended to
          continue in force, including, without limitation, the provisions relating to Intellectual Property Rights,
          Confidential Information and Limitation of Liability.
        </li>
        <li>
          21.7 Assignment. Oqcam may assign, transfer and/or subcontract the rights and obligations under the Agreement
          to its Affiliates, provided, however, that such assignment shall not relieve Oqcam of its obligations under
          this Agreement. Customer shall not assign or otherwise transfer any of its rights or obligations under the
          Agreement without Oqcam’s prior written consent. Subject to any restrictions on assignment herein contained,
          the provisions of the Agreement shall inure to the benefit of and shall be binding upon the Parties hereto and
          their respective heirs, legal representatives, successors and assignees.
        </li>
        <li>
          21.8 Force Majeure. In the event that either Party is prevented from performing or is unable to perform any of
          its non-monetary obligations under this Agreement due to an Act of God, fire, casualty, flood, earthquake,
          war, strike, lockout, epidemic, destruction of production facilities, riot, insurrection, unavailability of
          material, or any other cause beyond the reasonable control of the Party invoking this section, provided such
          Party has used reasonable efforts to mitigate the effects of such force majeure event, such Party shall give
          prompt written notice to the other Party, its performance shall be excused, and the time for performance shall
          be extended for the period of delay or inability to perform due to such occurrences.
        </li>
        <li>
          21.9 Publicity. Upon written approval by Customer, Oqcam shall have the right to use any trademarks, logos or
          other marks of Customer (including Customer’s corporate name) for Customer references on Oqcam’s website,
          social media announcements and sales presentations.
        </li>
        <li>
          21.10 Relationship between the Parties. The relationship between Oqcam and Customer is that of independent
          contractors. Neither Party is agent for the other and neither Party has any authority to make any contracts,
          whether expressly or by implication, in the name of the other Party, without that Party’s prior written
          consent for express purposes connected with the performance of the Agreement.
        </li>
        <li>
          21.11 Notices. Any notice required to be served by the Agreement shall in first instance be given by
          electronic mail. Notices given to Customer shall be done to the email address set out in the Order Form (or in
          case no email address is available via post to Customer’s registered address). All notices given by electronic
          mail, shall only be valid in case confirmation of receipt was expressly given by electronic mail by the
          receiving Party. In case no confirmation of receipt was given by Oqcam within five (5) Business Days, all
          notices can be done in writing and served by registered letter addressed to either Party at its address given
          in the Order Form or to such other address as a Party may designate by notice hereunder.
        </li>
        <li>
          21.12 Conflict. In case of conflict between the provisions of the contractual documents executed between the
          Parties, the first document shall prevail on the latter: (i) the Order Form, (ii) the Terms and Conditions
          (iii) the Schedules, unless explicitly agreed otherwise in writing between the Parties.
        </li>
        <li>
          21.13 Language. The Agreement is entered into in the English language only, which language shall be
          controlling in all respects. Furthermore, all communications and notices made or given pursuant to the
          Agreement shall be in the English.
        </li>
        <li>
          21.14 Applicable law and jurisdiction. The Agreement shall be governed by and construed in accordance with the
          laws of Delaware without giving effect to any choice or conflict of law provision or rule that would require
          or permit the application of the laws of any other jurisdiction. The Parties hereto submit to the exclusive
          jurisdiction of the competent courts of Delaware. <br />
          <a href="/terms-of-use">The EULA referenced herein can be found here</a>
        </li>
      </ul>
      <h2>ANNEX 1: DATA PROCESSING AGREEMENT</h2>
      <h3>General</h3>
      <ul>
        <li>
          1.1 This Data Processing Agreement (“DPA”) sets out the additional terms, requirements and conditions on which
          the Processor will process Personal Data when providing services under the Agreement. This DPA contains the
          mandatory clauses required by Article 28(3) of the GDPR for contracts between controllers and processors.
        </li>
        <li>1.2 This DPA is subject to the terms of the Agreement and is incorporated into the Agreement.</li>
        <li>1.3 In the case of conflict or ambiguity between:</li>
        <ul>
          <li>
            (i) any provision contained in the body of this DPA and any provision contained in the Appendices, the
            provision in the body of this DPA will prevail; and
          </li>
          <li>
            (ii) any of the provisions of this DPA and the provisions of the Agreement, the provisions of this DPA will
            prevail.
          </li>
        </ul>
      </ul>
      <h3>Definitions</h3>
      <ul>
        <li>The following definitions and rules of interpretation apply in this DPA.</li>
        <ul>
          <li>
            "Business Purposes" means the services described in the Agreement and/or any other purpose specifically
            identified in Appendix A.
          </li>
          <li>
            "Data Protection Legislation" means any applicable statutory regulation of the European Union, including,
            without being limited to acts, directives and regulations, for the protection of personal data, in
            particular the GDPR, and the applicable implementation legislation under Belgian law.
          </li>
          <li>
            "GDPR" means Regulation (EU) 2016/679 of the European Parliament and of Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of personal data and on the free movement of
            such data and repealing Directive 95/46/EC (General Data Protection Regulation).
          </li>
          <li>
            "Controller, Data Protection Impact Assessment, Data Subject, Personal Data, Personal Data Breach,
            process(ing) and Processor" shall have the same meaning as in the GDPR.
          </li>
        </ul>
      </ul>
      <h3>Personal Data types and processing purposes</h3>
      <ul>
        <li>
          3.1 The Controller retains control of the Personal Data and remains responsible for its compliance obligations
          under the applicable Data Protection Legislation, including providing any required notices and obtaining any
          required consents, and for the processing instructions it gives to the Processor. The Controller shall inform
          the Processor of any additional national and/or sector-specific mandatory legislation that applies to the
          processing by the Processor as a result of the processing by the Controller.
        </li>
        <li>
          3.2 Appendix A describes the nature and purpose of processing, the retention term(s) and the Personal Data
          categories and Data Subject types in respect of which the Processor may process to fulfil the Business
          Purposes.
        </li>
      </ul>
      <h3>Processor's obligations</h3>
      <ul>
        <li>
          4.1 The Processor will only process the Personal Data to the extent, and in such a manner, as is necessary for
          the Business Purposes in accordance with the Controller's written instructions. The Processor will not process
          the Personal Data for any other purpose or in a way that does not comply with this DPA or the Data Protection
          Legislation. The Processor must promptly notify the Controller if, in its opinion, the Controller's
          instruction would not comply with the Data Protection Legislation.
        </li>
        <li>
          4.2 The Processor will maintain the confidentiality of all Personal Data and will not disclose Personal Data
          to third parties unless the Controller or this DPA specifically authorises the disclosure, or as required by
          law. If a law, court, regulator or supervisory authority requires the Processor to process or disclose
          Personal Data, the Processor must first inform the Controller of the legal or regulatory requirement and give
          the Controller an opportunity to object or challenge the requirement, unless the law prohibits such notice.
        </li>
        <li>
          4.3 The Processor will reasonably assist the Controller with meeting the Controller's compliance obligations
          under the Data Protection Legislation, taking into account the nature of the Processor's processing and the
          information available to the Processor, including in relation to Data Subject rights, Data Protection Impact
          Assessments and reporting to and consulting with supervisory authorities under the Data Protection
          Legislation.
        </li>
        <li>
          4.4 Within the Fees of the Agreement, a maximum of 2 hours of assistance per contract year is included under
          this DPA. Any assistance in excess of the included assistance may be charged to the Controller on the basis of
          the hours worked and the applicable standard hourly rates of the Processor, unless the assistance is required
          because of the Processor’s non-compliance with this DPA. Payment by the Controller to the Processor for the
          services under this Agreement will take place in accordance with the provisions in the Agreement.
        </li>
      </ul>
      <h3>Processor’s employees</h3>
      <ul>
        <li>5.1 The Processor will ensure that all employees:</li>
        <ul>
          <li>
            (i) are informed of the confidential nature of the Personal Data and are bound by confidentiality
            obligations and use restrictions in respect of the Personal Data; and
          </li>
          <li>
            (ii) are aware the Processor's duties and their personal duties and obligations under the Data Protection
            Legislation and this DPA.
          </li>
        </ul>
      </ul>
      <h3>Security</h3>
      <ul>
        <li>
          6.1 The Processor must implement appropriate technical and organisational measures against unauthorised or
          unlawful processing, access, disclosure, copying, modification, storage, reproduction, display or distribution
          of Personal Data, and against accidental or unlawful loss, destruction, alteration, disclosure or damage of
          Personal Data, as further described in Appendix B.
        </li>
        <li>
          6.2 The Processor must implement such measures to ensure a level of security appropriate to the risk involved,
          including as appropriate:
        </li>
        <ul>
          <li>(i) the pseudonymisation and encryption of personal data;</li>
          <li>
            (ii) the ability to ensure the ongoing confidentiality, integrity, availability and resilience of processing
            systems and services;
          </li>
          <li>
            (iii) the ability to restore the availability and access to personal data in a timely manner in the event of
            a physical or technical incident; and
          </li>
          <li>
            (iv) a process for regularly testing, assessing and evaluating the effectiveness of security measures.
          </li>
        </ul>
      </ul>
      <h3>Personal Data Breach</h3>
      <ul>
        <li>
          7.1 The Processor will without undue delay and in any case within 48 hours notify the Controller if it becomes
          aware of a Personal Data Breach.
        </li>
        <li>
          7.2 Where the Processor becomes aware of a Personal Data Breach, it shall, without undue delay, also provide
          the Controller with the following information:
        </li>
        <ul>
          <li>
            (i) description of the nature thereof, including the categories and approximate number of both Data Subjects
            and Personal Data records concerned;
          </li>
          <li>(ii) the likely consequences; and</li>
          <li>
            (iii) description of the measures taken or proposed to be taken to address the Personal Data Breach,
            including measures to mitigate its possible adverse effects.
          </li>
        </ul>
        <li>
          7.3 Immediately following a Personal Data Breach, the Parties will co- ordinate with each other to investigate
          the matter. The Processor will reasonably co-operate with the Controller in the Controller's handling of the
          matter, including:
        </li>
        <ul>
          <li>(i) assisting with any investigation;</li>
          <li>
            (ii) taking reasonable and prompt steps to mitigate the effects and to minimise any damage resulting from
            the Personal Data Breach.
          </li>
        </ul>
        <li>
          7.4 The Processor will not inform any third party of any Personal Data Breach without first obtaining the
          Controller's prior written consent, except when required to do so by law.
        </li>
        <li>
          7.5 The Controller shall cover all reasonable expenses associated with the Processor’s performance under this
          Clause 7 unless the matter arose from the Processor's negligence, wilful misconduct or breach of this DPA.
        </li>
      </ul>
      <h3>Cross-border transfers of Personal Data</h3>
      <ul>
        <li>
          8.1 The Processor (or any Sub-Processor) shall not transfer or otherwise process Personal Data outside the
          European Economic Area (EEA) without obtaining the Controller's prior written consent.
        </li>
        <li>
          8.2 Such consent of Controller is not required when the transfer of Personal Data to countries outside the EEA
          is (i) required for the Processor’s Affiliates for support and back-up purposes (subject to clause 8.3 being
          complied with) or (ii) mandatory under EU or EU member state provisions.
        </li>
        <li>
          8.3 When transferring Personal Data outside the EEA, the Processor shall transfer Personal Data with the
          application of the standard contractual clauses (the European Commission’s decision of 5 February 2010 on
          standard contractual clauses for the transfer of Personal Data to processors established in third countries
          which do not ensure an adequate level of data protection), any successor standard contractual clauses
          published by the European Commission or otherwise ensure that such transfer is lawful under applicable Data
          Protection Laws.
        </li>
      </ul>
      <h3>Subcontractors</h3>
      <ul>
        <li>9.1 The Processor may only authorise a third party (Sub-Processor) to process the Personal Data if:</li>
        <ul>
          <li>
            (i) the Controller is provided with an opportunity to object to the appointment of each Sub-Processor within
            14 days after the Processor has notified the Controller of its intention to appoint such Sub- Processor, it
            being understood that the Controller shall only object to such appointment in writing and on reasonable and
            evidenced grounds; and
          </li>
          <li>
            (ii) the Processor enters into a written contract with the Sub-Processor that contains terms substantially
            the same as those set out in this DPA, in particular, in relation to requiring appropriate technical and
            organisational data security measures, and, upon the Controller's written request, provides the Controller
            with copies of such contracts.
          </li>
        </ul>
        <li>
          9.2 Those Sub-Processors approved at the commencement of this DPA are as set out in Appendix A. The Processor
          must list all approved subcontractors in Appendix A and include any Sub-Processor's name, location and contact
          information for the person responsible for data protection compliance.
        </li>
        <li>
          9.3 Without prejudice to Clause 14.1 the Processor shall remain fully liable to the Controller for any failure
          by a Sub-Processor to fulfil its obligations in relation to the processing of the Personal Data.
        </li>
      </ul>
      <h3>Complaints, Data Subject requests and third-party rights </h3>
      <ul>
        <li>
          10.1 The Processor must take such technical and organisational measures as agreed in writing between the
          Parties, and promptly provide such information to the Controller as the Controller may reasonably require, to
          enable the Controller to comply with:
        </li>
        <ul>
          <li>
            (i) the rights of Data Subjects under the Data Protection Legislation, including subject access rights, the
            rights to rectify and erase personal data, object to the processing and automated processing of personal
            data, and restrict the processing of personal data; and
          </li>
          <li>
            (ii) information or assessment notices served on the Controller by any supervisory authority under the Data
            Protection Legislation.
          </li>
        </ul>
        <li>
          10.2 The Processor must notify the Controller immediately if it receives any complaint, notice or
          communication that relates directly or indirectly to the processing of the Personal Data or to either Party's
          compliance with the Data Protection Legislation.
        </li>
        <li>
          10.3 The Processor must notify the Controller as soon as reasonably possible if it receives a request from a
          Data Subject for access to their Personal Data or to exercise any of their related rights under the Data
          Protection Legislation.
        </li>
        <li>
          10.4 The Processor will reasonably cooperate with, and assist, the Controller in responding to any complaint,
          notice, communication or Data Subject request.
        </li>
        <li>
          10.5 The Processor must not disclose the Personal Data to any Data Subject or to a third party other than at
          the Controller's request or instruction, as provided for in this DPA or as required by law.
        </li>
      </ul>
      <h3>Term and termination</h3>
      <ul>
        <li>11.1 This DPA will remain in full force and effect so long as:</li>
        <li>(i) the Agreement remains in effect; or</li>
        <li>
          (ii) the Processor retains any Personal Data related to the Agreement in its possession or control (“Term”).
        </li>
        <li>
          11.2 Any provision of this DPA that expressly or by implication should come into or continue in force on or
          after termination of the Agreement (including, but not limited to, Clause 14.1) will remain in full force and
          effect.
        </li>
      </ul>
      <h3>Data return and destruction</h3>
      <ul>
        <li>
          12.1 On termination of the Agreement for any reason or expiry of its term, the Processor will securely delete
          or destroy or, if directed in writing by the Controller, return and not retain, all or any Personal Data
          related to this DPA in its possession or control.
        </li>
        <li>
          12.2 If any law, regulation, or government or regulatory body requires the Processor to retain any documents
          or materials that the Processor would otherwise be required to return or destroy, it will notify the
          Controller in writing of that retention requirement, giving details of the documents or materials that it must
          retain, the legal basis for retention, and establishing a specific timeline for destruction once the retention
          requirement ends.
        </li>
        <li>
          12.3 The Processor will certify in writing that it has destroyed the Personal Data within 14 days after it
          completes the destruction.
        </li>
      </ul>
      <h3>Audit</h3>
      <ul>
        <li>
          13.1 The Processor shall make available to the Controller all information necessary to demonstrate compliance
          with the obligations under this DPA and the Data Protection Legislation and allow the Controller and its
          authorized auditors to perform audits regarding the compliance by the Processor with its obligations under
          this DPA and the Data Protection Legislation.
        </li>
        <li>
          13.2 Any such audit may not take place more than once every contract year, shall be at the sole expense of the
          Controller and shall be subject to the Controller providing the Processor with at least thirty (30) days prior
          written notice of its intention to perform an audit. The audit shall take place during the normal business
          hours and shall not unreasonably interfere with the Processor’s business activities. The Processor shall have
          the right to require Controller and any third-party auditor to enter into a non-disclosure agreement prior to
          performing the audit.
        </li>
      </ul>
      <h3>Miscellaneous</h3>
      <ul>
        <li>
          14.1 To the extent permitted under applicable law, any limitations and/or exclusions of liability in the
          Agreement are applicable to this DPA.
        </li>
        <li>
          14.2 If at any time during the Term, one of the provisions of this DPA, is determined to be or to have become
          invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions of
          the DPA shall not in any way be affected or impaired. The Parties shall negotiate in good faith to replace
          such invalid, illegal or unenforceable provision with a valid, legal and enforceable provision the effect of
          which comes as close as possible to that of the invalid, illegal or unenforceable provision.
        </li>
        <li>
          14.3 This DPA will be governed by, and construed in accordance with, the laws applicable to the Agreement. The
          same court as stated in the Agreement shall have exclusive jurisdiction for any disputes arising from or in
          connection with this DPA.
        </li>
      </ul>
      <h2>APPENDIX A TO THE DATA PROCESSING AGREEMENT: DESCRIPTION OF PROCESSING AND CONTACT INFORMATION</h2>
      <h3>Additional Business Purposes and specific instructions regarding the processing</h3>
      <p>
        Processor will host and maintain a cloud-based product which includes MES (Manufacturing Execution System)
        functionality.
      </p>
      <p>
        As part of those functions, records containing Personal Data which is entered by the Controller, may be
        processed by the Processor.
      </p>
      <p>
        The Oqcam software identifies users of the software based on their email address and password and keeps records
        of which actions the users execute.
      </p>
      <h3>Nature of the processing</h3>
      <ul>
        <li>collection</li>
        <li>recording</li>
        <li>storage</li>
        <li>retrieval</li>
        <li>consultation</li>
        <li>use</li>
      </ul>
      <h3>Categories of Data Subjects</h3>
      <ul>
        <li>Processor employees and/or consultants</li>
        <li>Customers of Processor</li>
      </ul>
      <h3>Categories of Personal Data</h3>
      <ul>
        <li>Personal identification data (name, address, telephone number, etc.)</li>
        <li>Electronic identification data (IP address, MAC address, cookies, etc.)</li>
        <li>Health related data (physical health, mental health, genetic data, treatments, prescriptions, etc.)</li>
        <li>Pictures or videos</li>
      </ul>
      <h3>Retention period</h3>
      <ul>
        <li>Specific retention periods, please specify: until two weeks after the termination of the agreement</li>
      </ul>
      <h3>Contact information person responsible for data protection compliance</h3>
      <table>
        <tr>
          <td>Full name</td>
          <td>Benoit Soete</td>
        </tr>
        <tr>
          <td>Title</td>
          <td>Head of Compliance</td>
        </tr>
        <tr>
          <td>E-mail</td>
          <td>
            <a href="mailto:support@oqcam.com">support@oqcam.com</a>
          </td>
        </tr>
      </table>
      <h3>List of Sub-Processors</h3>
      <table>
        <tbody>
          <tr>
            <th>Company name</th>
            <th>Location of processing</th>
            <th>Nature of sub-processing (description of services)</th>
          </tr>
          <tr>
            <td>Google LLC</td>
            <td>Europe / USA</td>
            <td>Cloud Infrastructure</td>
          </tr>
          <tr>
            <td>Microsoft Corporation</td>
            <td>Europe / USA</td>
            <td>Cloud Infrastructure</td>
          </tr>
          <tr>
            <td>Github Inc</td>
            <td>USA</td>
            <td>Code and Documentation</td>
          </tr>
          <tr>
            <td>Sentry Inc</td>
            <td>USA</td>
            <td>Error reporting</td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <h2>APPENDIX B TO THE DATA PROCESSING AGREEMENT: TECHNICAL AND ORGANISATIONAL MEASURES</h2>
      <p>
        The processor has taken several organisational and technical measures to safeguard the data the controllers
        entrust it with. This appendix offers an overview of these measures. Please note that this appendix is not
        complete nor detailed. For more information, our SOC2 Audit Reports are available under NDA.
      </p>
      <h3>Technical measures</h3>
      <ul>
        <li>
          1.1 Security Overview. Oqcam hardens against bad actors in various ways. The protection focusses on a few
          important concepts:
        </li>
        <ul>
          <li>
            (i) Encryption at Rest: all data is stored in encrypted storage buckets, using current Best Practices as
            defined by the Cloud Providers we use;
          </li>
          <li>
            (ii) Encryption in Transit: all traffic entering or leaving our platform is encrypted using industry
            standard TLS / HTTPS mechanisms so it can’t be intercepted between the browser or machine and the Oqcam
            platform;
          </li>
          <li>
            (iii) Separation of customer raw data: every tenant on our platform has a separate storage bucket, with
            separate encryption keys and credentials.
          </li>
          <li>
            (iv) Separation of Oqcam data and customer meta-data: The Oqcam data and customer meta-data for each tenant
            is stored in a separate Database;
          </li>
          <li>
            (v) Separation of Oqcam processing: We run separate copies of key components of our solution per tenant;
          </li>
          <li>
            (vi) Secure Software Development Life Cycle: all our software code is checked into version control, it is
            reviewed before being merged into the main codebase and has to go through functional and security tests
            before it is considered production-ready;
          </li>
          <li>
            (vii) Security Scanning: we scan all software for known vulnerabilities at build and at regular intervals.
          </li>
        </ul>
        <li>1.2 Secure Infrastructure.</li>
        <ul>
          <li>
            (i) Oqcam operates multiple Oqcam cluster environments. These clusters are Kubernetes based and use the
            Kubernetes offerings provided by the largest Cloud Infrastructure Providers in the world (Google Cloud,
            Amazon Web Services, etc.);
          </li>
          <li>
            (ii) All infrastructure components are described in manifests, using the “infrastructure as code” paradigm.
            This allows version-controlled, peer- reviewed, and reproducible infrastructure components;
          </li>
          <li>
            (iii) On top of the Kubernetes offerings, we use Object Stores, managed Load Balancers, and managed DNS
            services;
          </li>
          <li>(iv) Next to our production clusters, we have separate development and staging clusters;</li>
          <li>
            (v) We use the authentication mechanisms provided by the cloud vendors to secure the access to resources.
            All machines have restrictive roles assigned using IAM profiles and roles provided by the cloud vendor;
          </li>
          <li>
            (vi) We use Auth0 for authentication, so we do not store user credentials; the different components use
            short-lived JWT tokens for AuthNZ. There are separate password databases and Auth0 connections per customer.
          </li>
        </ul>
        <li>1.3 Reliability, backups, and disaster recovery.</li>
        <ul>
          <li>
            (i) Being based on a Cloud-native strategy, our Oqcam software components scale well and will auto-scale and
            auto-heal when needed;
          </li>
          <li>
            (ii) All data (databases and object-stores) are backed up to geographically separated storage facilities at
            a different cloud provider, using separate security access controls;
          </li>
          <li>
            (iii) Our SRE engineers use automation tools and well-descript procedures to keep our FactoryOS clusters up
            to date. This allows them to quickly update, upgrade, scale a cluster and perform disaster recovery
            operations;
          </li>
          <li>(iv) Regular DRP tests are planned, executed and reviewed.</li>
        </ul>
      </ul>
      <h3>Organisational measures</h3>
      <ul>
        <li>
          Oqcam's robust Security Program adheres to SOC2 security frameworks and undergoes ongoing enhancements to
          ensure top-tier protection. Our Information Security Management System (ISMS) has been rigorously audited
          against SOC2 Compliance controls, resulting in a highly successful SOC2 Type 2 Audit report delivered in March
          2021. To maintain the highest security standards, our certification is renewed annually.
        </li>
      </ul>
    </TermsAndConditionsContentContainer>
  );
};
